<script setup lang="ts">
interface Emits {
  (e: "close"): void;
}

const { track } = useTracking();
const emit = defineEmits<Emits>();
const { isIos, isAndroid } = useDevice();

const downloadOptions = computed(() => {
  if (isIos)
    return [
      {
        text: "Available on the",
        storeName: "App Store",
        url: EXTERNAL_LINKS.iosDownload,
        icon: "ion:logo-apple",
      },
    ];

  if (isAndroid)
    return [
      {
        text: "Get in on",
        storeName: "Google Play",
        url: EXTERNAL_LINKS.playStoreDownload,
        icon: "logos:google-play-icon",
      },
    ];

  return [
    {
      text: "Get in on",
      storeName: "Google Play",
      url: EXTERNAL_LINKS.playStoreDownload,
      icon: "logos:google-play-icon",
    },
    {
      text: "Available on the",
      storeName: "App Store",
      url: EXTERNAL_LINKS.iosDownload,
      icon: "ion:logo-apple",
    },
  ];
});

const navigateToStore = (url: string) => {
  track(TrackingEvent.APPSTORE_LINK_CLICKED);
  emit("close");
  return navigateTo(url, {
    external: true,
    open: {
      target: "_blank",
    },
  });
};
</script>

<template>
  <div class="flex flex-col sm:flex-row gap-3 sm:justify-center">
    <div
      v-for="(option, index) in downloadOptions"
      :key="index"
      @click="navigateToStore(option.url)"
      class="bg-dark-gray flex gap-x-1 items-center w-full sm:w-56 p-2 rounded-md cursor-pointer hover:scale-[1.02] transition-transform duration-300 hover:shadow-lg"
    >
      <Icon class="h-10 w-10" :name="option.icon" />
      <div class="flex flex-col text-white items-start">
        <p class="text-sm">{{ option.text }}</p>
        <p class="text-lg font-semibold">{{ option.storeName }}</p>
      </div>
    </div>
  </div>
</template>
